import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";

import './styles/experience.css'

function Experience() {

    const experiences = [
        {
            image: 'https://ucontinental.edu.pe/site/wp-content/uploads/2023/04/foto-dis-10_10-1.png',
            title: 'Experiencia Educativa',
            texto: 'Descubre en Colegios & Academias Sigma una experiencia educativa completa que trasciende las aulas. Desde actividades extracurriculares hasta laboratorios especializados para tu desarrollo académico y personal.'
        },
        {
            image: 'https://ucontinental.edu.pe/site/wp-content/uploads/2023/05/shutterstock_87982495-_1_-1024x683.webp',
            title: 'Aprendizaje Experiencial',
            texto: 'Sumérgete en una experiencia de aprendizaje práctico en Colegios & Academias Sigma. Nuestros programas están diseñados para brindarte experiencias prácticas que te prepararán para los desafíos del mundo real.'
        },
        {
            image: 'https://ucontinental.edu.pe/site/wp-content/uploads/2023/04/Rectangle-3350.png',
            title: 'Innovación Educativa',
            texto: 'Explora la innovación educativa en Colegios & Academias Sigma. Nuestros programas y actividades extracurriculares fomentan la creatividad, el pensamiento crítico y la resolución de problemas.'
        },
        {
            image: 'https://ucontinental.edu.pe/site/wp-content/uploads/2023/05/Rectangle-3350-2.png',
            title: 'Desarrollo Personal',
            texto: 'Cultiva tu desarrollo personal en Colegios & Academias Sigma. Nuestros programas incluyen actividades y talleres que te ayudarán a desarrollar habilidades sociales, emocionales y de liderazgo.'
        },
        {
            image: 'https://ucontinental.edu.pe/site/wp-content/uploads/2023/05/Rectangle-3350-1.png',
            title: 'Colaboración Comunitaria',
            texto: 'Fomentamos la colaboración comunitaria en Colegios & Academias Sigma. A través de actividades de servicio comunitario y proyectos de impacto social, te invitamos a contribuir positivamente a tu entorno.'
        }
    ];

    return (

        <section className={`--section-experience`}>

            <div className={`--content-section-experience`}>

                <div className={`--titles-experience`}>

                    <p className='--background-txt'>Experiencia</p>
                    <h4 className='--tit-secondary'>Comunidad Sigma</h4>
                    <p className='--parragraph'>
                        En Colegios & Academias Sigma, te ofrecemos la oportunidad de experimentar 
                        un enfoque educativo completo. Nuestros programas incluyen impactantes actividades 
                        extracurriculares, laboratorios de última generación, una incubadora de emprendimientos, 
                        oportunidades laborales, internacionalización, eventos culturales y mucho más. 
                        Todo diseñado para que descubras y desarrolles tu talento a través de un aprendizaje 
                        vivencial único. Únete a nosotros y descubre un mundo de posibilidades educativas en 
                        Colegios & Academias Sigma.
                    </p>

                    <Link to={'/experiencia'} className='--a-btn'>Conoce la vida en Sigma</Link>

                </div>

                <div className={`--content-experience`}>

                    <Swiper
                        className='--swip --swip-experience'
                        loop={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                        }}
                    >

                        {experiences.map((experience, index) => (
                            <SwiperSlide key={index}>
                                <div className='--card-experience'>
                                    <div className='--card-experience-img' style={{backgroundImage: `url(${experience.image})`}}></div>
                                    <div className='--card-experience-txt'>
                                        <h3>{experience.title}</h3>
                                        <p>{experience.texto}</p>
                                        <Link>Ver más</Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        
                    </Swiper>

                </div>

            </div>

        </section>

    )

}

export default Experience