import React from 'react'
import HeroEdu from '../components/Sections/HeroEdu'

import banner from '../static/img/banner-primaria.png'
import AboutLevel from '../components/Sections/AboutLevel'
import RequirementsLevel from '../components/Sections/RequirementsLevel'

function PrimariaView() {

    return (

        <>
        
            <HeroEdu banner={banner} title={'Educación Primaria'} content={'Con una metodología educativa dinámica y enriquecedora, con maestros dedicados y programas curriculares innovadores que fomentan el aprendizaje integral.'} go={'Solicitar información'} />

            <AboutLevel level={'primaria'} txtP={'Educación Primaria'} txtS={'para los guerreros del futuro.'} />

            <RequirementsLevel level={'primaria'} />

        </>

    )

}

export default PrimariaView