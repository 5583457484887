import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from 'swiper/modules';

import "swiper/css";
import 'swiper/css/pagination';

import './styles/hero.css'

function Hero() {

    return (

        <section className={`--section`}>

            <div className={`--content-section`}>

                <Swiper className={`--courusel`} pagination={true} autoplay={{ delay: 3000 }} loop={true}  modules={[Pagination, Autoplay]}>
                    <SwiperSlide>1</SwiperSlide>
                    <SwiperSlide>2</SwiperSlide>
                    <SwiperSlide>3</SwiperSlide>
                    <SwiperSlide>4</SwiperSlide>
                    <SwiperSlide>5</SwiperSlide>
                    <SwiperSlide>6</SwiperSlide>
                </Swiper>

            </div>

        </section>

    )

}

export default Hero