import React, { useState } from 'react'
import './styles/aboutlevel.css'

import aboutInicialImage from '../../static/img/about-inicial.png';

function AboutLevel({ level, txtP, txtS }) {

    const [ eyeslash, setEyeslash ] = useState('about');

    const titleAboutLevel = {
        'inicial': 'INICIAL (3, 4, 5 AÑOS)',
        'primaria': 'PRIMARIA (1, 2, 3, 4, 5, 6 GRADO)',
        'secundaria': 'SECUNDARIA (1, 2, 3, 4, 5 GRADO)',
        'prepoli': 'PRE POLI (POLICIA, EJÉRCITO, MARINA, FURZA AÉREA)',
    }
    const textAboutLevel = {
        'inicial': 'Fomentar la creatividad a través del juego y la exploración, utilizando recursos tecnológicos de vanguardia para inspirar a los más pequeños. Con una perspectiva innovadora, los preparamos para un futuro lleno de posibilidades, donde puedan desarrollar su potencial desde temprana edad.',
        'primaria': 'Estimular el aprendizaje activo y la curiosidad, utilizando herramientas tecnológicas para potenciar el desarrollo académico y personal de nuestros estudiantes. Con un enfoque innovador, los preparamos para abrazar los desafíos del mañana y convertirse en líderes de su propio aprendizaje',
        'secundaria': 'Promover la excelencia académica y el pensamiento crítico, integrando tecnología de vanguardia para enriquecer la experiencia educativa. Con una visión innovadora, los capacitamos para afrontar los retos del mundo moderno y destacarse como ciudadanos globales comprometidos.',
        'prepoli': 'Impulsar la formación integral y el liderazgo, incorporando tecnología de punta para fortalecer las habilidades técnicas y tácticas necesarias en el ámbito pre policial y militar. Con una mentalidad innovadora, los preparamos para enfrentar con éxito los desafíos de servir a la comunidad con honor y compromiso.',
    };
    const textDevelopLevel = {
        'inicial': ['Desarrollará al máximo su capacidad creadora.', 'Desarrollará sus capacidades por iniciativa propia.', 'Desarrollará su psicomotricidad.', 'Desarrollará su conducta, carácter y personalidad.'],
        'primaria': ['Desarrollará al máximo su capacidad creadora.', 'Desarrollará sus capacidades por iniciativa propia.', 'Desarrollará su psicomotricidad.', 'Desarrollará su conducta, carácter y personalidad.'],
        'secundaria': ['Desarrollará al máximo su capacidad creadora.', 'Desarrollará sus capacidades por iniciativa propia.', 'Desarrollará su psicomotricidad.', 'Desarrollará su conducta, carácter y personalidad.'],
        'prepoli': ['Desarrollará al máximo su capacidad creadora.', 'Desarrollará sus capacidades por iniciativa propia.', 'Desarrollará su psicomotricidad.', 'Desarrollará su conducta, carácter y personalidad.'],
    }
    const textLearnLevel = {
        'inicial': ['Expresar sus emociones de una manera libre y espontánea.', 'Aprenderá de una manera vivencial.', 'Tendrá un apredizaje lógicos y matemáticos.', 'Identificación de su Ambiente (Ciencia y Ambiente).'],
        'primaria': ['Desarrollará al máximo su capacidad creadora.', 'Desarrollará sus capacidades por iniciativa propia.', 'Desarrollará su psicomotricidad.', 'Desarrollará su conducta, carácter y personalidad.'],
        'secundaria': ['Desarrollará al máximo su capacidad creadora.', 'Desarrollará sus capacidades por iniciativa propia.', 'Desarrollará su psicomotricidad.', 'Desarrollará su conducta, carácter y personalidad.'],
        'prepoli': ['Desarrollará al máximo su capacidad creadora.', 'Desarrollará sus capacidades por iniciativa propia.', 'Desarrollará su psicomotricidad.', 'Desarrollará su conducta, carácter y personalidad.'],
    }
    const textBenefitsLevel = {
        'inicial': ['Taller de Karate.', 'Taller de Inglés.', 'Taller de Danza.', 'Taller de Pastelerías.'],
        'primaria': ['Desarrollará al máximo su capacidad creadora.', 'Desarrollará sus capacidades por iniciativa propia.', 'Desarrollará su psicomotricidad.', 'Desarrollará su conducta, carácter y personalidad.'],
        'secundaria': ['Desarrollará al máximo su capacidad creadora.', 'Desarrollará sus capacidades por iniciativa propia.', 'Desarrollará su psicomotricidad.', 'Desarrollará su conducta, carácter y personalidad.'],
        'prepoli': ['Desarrollará al máximo su capacidad creadora.', 'Desarrollará sus capacidades por iniciativa propia.', 'Desarrollará su psicomotricidad.', 'Desarrollará su conducta, carácter y personalidad.'],
    }

    const titleDescription = titleAboutLevel[level];
    const textDescription = textAboutLevel[level];
    const textDevelop = textDevelopLevel[level];
    const textLearn = textLearnLevel[level];
    const textBenefits = textBenefitsLevel[level];

    return (

        <>
            
            <section className={`--section-aboutLevel`}>

                <div className={`--content-aboutLevel`}>

                    <div className='--table'>

                        <div className='--table-head'>

                            <div className={`--table-head-row ${eyeslash === 'about' ? '--table-head-row--active' : ''}`} onClick={() => setEyeslash('about')}>
                                <span>Sobre el grado</span>
                            </div>
                            <div className={`--table-head-row ${eyeslash === 'develop' ? '--table-head-row--active' : ''}`} onClick={() => setEyeslash('develop')}>
                                <span>¿Qué desarrollará?</span>
                            </div>
                            <div className={`--table-head-row ${eyeslash === 'learn' ? '--table-head-row--active' : ''}`} onClick={() => setEyeslash('learn')}>
                                <span>¿Qué aprenderá?</span>
                            </div>
                            <div className={`--table-head-row ${eyeslash === 'benefits' ? '--table-head-row--active' : ''}`} onClick={() => setEyeslash('benefits')}>
                                <span>Beneficios</span>
                            </div>
                            <div className={`--table-head-row ${eyeslash === 'team' ? '--table-head-row--active' : ''}`} onClick={() => setEyeslash('team')}>
                                <span>Nuestro Equipo</span>
                            </div>

                        </div>

                        <div className='--table-body'>

                            {eyeslash === 'about' && (

                                <div className='--content-data'>

                                    <div className='--content-data-image'>

                                        <div className='--clip-path-image'>
                                            <img src={aboutInicialImage} alt='Educación Inicial | COLEGIOS & ACADEMIAS SIGMA | PREPOLI | INICIAL | PRIMARIA | SECUNDARIA | Fomentar la creatividad a través del juego y la exploración, utilizando recursos tecnológicos de vanguardia para inspirar a los más pequeños. Con una perspectiva innovadora, los preparamos para un futuro lleno de posibilidades, donde puedan desarrollar su potencial desde temprana edad.' />
                                        </div>

                                    </div>

                                    <div className='--content-data-texto'>

                                        <h2>
                                            <p className='--txt-primary'>{txtP}</p>
                                            <p className='--txt-secondary'>{txtS}</p>
                                        </h2>

                                        <p className='--txt-description'>{textDescription}</p>

                                    </div>

                                </div>

                            )}

                            {eyeslash === 'develop' && (

                                <div className='--content-develop'>

                                    <h2>{titleDescription}</h2>

                                    <p>{textDescription}</p>

                                    <ul className='--list-develop'>
                                        <li>
                                            <h4>Desarrollará</h4>
                                            <ul>
                                                {textDevelop.map((dev, index) => (
                                                    <li key={index} className='--sub-li'>{dev}</li>
                                                ))}
                                            </ul>
                                        </li>
                                    </ul>

                                </div>

                            )}

                            {eyeslash === 'learn' && (

                                <div className='--content-develop'>

                                    <h2>{titleDescription}</h2>

                                    <p>{textDescription}</p>

                                    <ul className='--list-develop'>
                                        <li>
                                            <h4>Aprenderá</h4>
                                            <ul>
                                                {textLearn.map((learn, index) => (
                                                    <li key={index} className='--sub-li'>{learn}</li>
                                                ))}
                                            </ul>
                                        </li>
                                    </ul>

                                </div>

                            )}

                            {eyeslash === 'benefits' && (

                                <div className='--content-develop'>

                                    <h2>{titleDescription}</h2>

                                    <p>{textDescription}</p>

                                    <ul className='--list-develop'>
                                        <li>
                                            <h4>Beneficios</h4>
                                            <ul>
                                                {textBenefits.map((benefits, index) => (
                                                    <li key={index} className='--sub-li'>{benefits}</li>
                                                ))}
                                                <li className='--sub-li'><b>Servicio Médico y Psicologico</b></li>
                                            </ul>
                                        </li>
                                    </ul>

                                </div>

                            )}

                            {eyeslash === 'team' && (

                                <div className='--content-develop'>

                                    <h2>{titleDescription}</h2>

                                    <p>{textDescription}</p>

                                    <ul className='--list-develop'>
                                        <li>
                                            <h4>Nuestro Equipo</h4>
                                            <ul className='--teams'>
                                                <li className='--team'>
                                                    <div className='--team-circle'></div>
                                                    <div className='--team-data'>
                                                        <h4 className='--name-team'>Name Team</h4>
                                                        <span className='--position-team'>Position team</span>
                                                    </div>
                                                </li>
                                                <li className='--team'>
                                                    <div className='--team-circle'></div>
                                                    <div className='--team-data'>
                                                        <h4 className='--name-team'>Name Team</h4>
                                                        <span className='--position-team'>Position team</span>
                                                    </div>
                                                </li>
                                                <li className='--team'>
                                                    <div className='--team-circle'></div>
                                                    <div className='--team-data'>
                                                        <h4 className='--name-team'>Name Team</h4>
                                                        <span className='--position-team'>Position team</span>
                                                    </div>
                                                </li>
                                                <li className='--team'>
                                                    <div className='--team-circle'></div>
                                                    <div className='--team-data'>
                                                        <h4 className='--name-team'>Name Team</h4>
                                                        <span className='--position-team'>Position team</span>
                                                    </div>
                                                </li>
                                                <li className='--team'>
                                                    <div className='--team-circle'></div>
                                                    <div className='--team-data'>
                                                        <h4 className='--name-team'>Name Team</h4>
                                                        <span className='--position-team'>Position team</span>
                                                    </div>
                                                </li>
                                                <li className='--team'>
                                                    <div className='--team-circle'></div>
                                                    <div className='--team-data'>
                                                        <h4 className='--name-team'>Name Team</h4>
                                                        <span className='--position-team'>Position team</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                </div>

                            )}

                        </div>

                    </div>

                </div>

            </section>
        
        </>

    )

}

export default AboutLevel