import React from 'react'
import HeroEdu from '../components/Sections/HeroEdu'

import banner from '../static/img/banner-secundaria.png';
import AboutLevel from '../components/Sections/AboutLevel';
import RequirementsLevel from '../components/Sections/RequirementsLevel';

function SecundariaView() {

    return (

        <>
        
            <HeroEdu banner={banner} title={'Educación Secundaria'} content={'Con una metodología educativa desafiante y orientada al futuro, con profesores comprometidos y programas académicos rigurosos que preparan a los estudiantes para el éxito académico y profesional.'} go={'Solicitar información'} />
        
            <AboutLevel level={'secundaria'} txtP={'Educación Secundaria'} txtS={'para el presente del pais.'} />

            <RequirementsLevel level={'secundaria'} />

        </>

    )

}

export default SecundariaView