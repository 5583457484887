import React from 'react'
import './styles/requirementslevel.css'

function RequirementsLevel({ level }) {

    const listRequirements = {
        'inicial': ['copia de d.n.i. (alumnos, padres)', 'ficha única de matrícula', 'Certificados de estudios', 'resolución directorial', 'certificado de conducta', 'acta de nacimiento'],
        'primaria': ['copia de d.n.i. (alumnos, padres)', 'ficha única de matrícula', 'Certificados de estudios', 'resolución directorial', 'certificado de conducta', 'acta de nacimiento'],
        'secundaria': ['copia de d.n.i. (alumnos, padres)', 'ficha única de matrícula', 'Certificados de estudios', 'resolución directorial', 'certificado de conducta', 'acta de nacimiento'],
        'prepoli': ['copia de d.n.i. (alumnos, padres)', 'ficha única de matrícula', 'Certificados de estudios', 'resolución directorial', 'certificado de conducta', 'acta de nacimiento'],
    };

    const requirements = listRequirements[level];

    return (

        <section className={`--section-requirements`}>

            <div className={`--content-requirements`}>

                <div className='--title-requirements'>
                    <h2>REQUISITOS</h2>
                    <p>¿Qué necesitas para ser parte de nuestra familia?</p>
                </div>

                <div className='--grid-requirements'>

                    {requirements.map((req, index) => (
                        <div key={index} className='--item-requirements'>

                            <div className='--ico-requirements'></div>

                            <div className='--txt-requirements'>
                                <h4>{req}</h4>
                            </div>

                        </div>
                    ))}

                    {level === 'inicial' && (
                        <div className='--item-requirements'>

                            <div className='--ico-requirements'></div>

                            <div className='--txt-requirements'>
                                <h4>Tarjeta de vacunación</h4>
                            </div>

                        </div>
                    )}

                </div>

            </div>

        </section>

    )

}

export default RequirementsLevel