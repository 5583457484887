import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';

import "swiper/css";

import './styles/whyabout.css'

function WhyAbout() {

    return (

        <section className='--section'>

            <div className={`--content-section-why`}>

                <div className={`--title-section`}>

                    <div className={`--title-section-h2`}>
                        <h2 className={`--txt-black`}>¿Por qué estudiar en</h2>
                    </div>
                    <div className={`--title-section-h2`}>
                        <h2 className={`--txt-primary`}>Colegios & Academias Sigma?</h2>
                    </div>

                </div>

                <div className={`--swiper-section`}>

                    <Swiper 
                        className={`--swip --swip-why`} 
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 5,
                                spaceBetween: 20,
                            },
                        }}
                        loop={true} 
                        autoplay={{delay: 3000}} 
                        modules={[Autoplay]}
                    >
                        <SwiperSlide className='--swipe-why-item'>1</SwiperSlide>
                        <SwiperSlide className='--swipe-why-item'>2</SwiperSlide>
                        <SwiperSlide className='--swipe-why-item'>3</SwiperSlide>
                        <SwiperSlide className='--swipe-why-item'>4</SwiperSlide>
                        <SwiperSlide className='--swipe-why-item'>5</SwiperSlide>
                    </Swiper>

                    <Swiper 
                        className={`--swip --swip-ben`} 
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                        }}
                        loop={true} 
                        autoplay={{delay: 3000}} 
                        modules={[Autoplay]}
                    >
                        <SwiperSlide className={`--swip-ben-item`}>1</SwiperSlide>
                        <SwiperSlide className={`--swip-ben-item`}>2</SwiperSlide>
                        <SwiperSlide className={`--swip-ben-item`}>3</SwiperSlide>
                        <SwiperSlide className={`--swip-ben-item`}>4</SwiperSlide>
                    </Swiper>

                </div>

            </div>

        </section>

    )

}

export default WhyAbout