import React from 'react'
import './styles/newsletter.css'
import { Link } from 'react-router-dom'

function Newletter() {

    const listNewsletter = [
        {
            image: 'https://ucontinental.edu.pe/site/wp-content/uploads/2024/03/ingles-23-768x525.jpg.webp',
            typo: 'Noticias',
            title: ' Centro de Idiomas UC firma Cooperación Interinstitucional con colegio Claretiano de Huancayo',
            texto: 'Alianza estratégica entre instituciones tiene como objetivo fortalecer la formación académica de los escolares mediante el dominio del inglés como lengua extranjera.'
        },
        {
            image: 'https://ucontinental.edu.pe/site/wp-content/uploads/2024/03/ingles-23-768x525.jpg.webp',
            typo: 'Noticias',
            title: ' Centro de Idiomas UC firma Cooperación Interinstitucional con colegio Claretiano de Huancayo',
            texto: 'Alianza estratégica entre instituciones tiene como objetivo fortalecer la formación académica de los escolares mediante el dominio del inglés como lengua extranjera.'
        },
        {
            image: 'https://ucontinental.edu.pe/site/wp-content/uploads/2024/03/ingles-23-768x525.jpg.webp',
            typo: 'Noticias',
            title: ' Centro de Idiomas UC firma Cooperación Interinstitucional con colegio Claretiano de Huancayo',
            texto: 'Alianza estratégica entre instituciones tiene como objetivo fortalecer la formación académica de los escolares mediante el dominio del inglés como lengua extranjera.'
        }
    ];

    return (

        <section className={`--section-newletter`}>

            <div className='--content-section-newletter'>

                <div className='--title-newletter'>
                    <p className='--background-txt'>NOTICIAS Y EVENTOS</p>
                    <h2>
                        <span className='--txt-black'>Noticias </span>
                        <span className='--txt-primary'>y Eventos</span>
                    </h2>
                    <p className='--txt-parragrap'>
                        <span>Sumérgete por completo en tu trayectoria educativa en Colegios & Academias Sigma y participa en una variedad de eventos, convocatorias y charlas informativas organizadas especialmente para ti.</span>
                        <span>Además, mantente al tanto de las últimas noticias, avances y entrevistas relevantes en tu campo profesional.</span>
                    </p>
                    <p className='--a-actions'>
                        <Link to={'/noticias-eventos'} className='--a-btn --a-btn-secondary'>Ver todas las noticias</Link>
                        <Link to={'/noticias-eventos'} className='--a-btn --a-btn-primary'>Ver todos los eventos</Link>
                    </p>
                </div>

                <div className='--content-newletter'>

                    {listNewsletter.map((news, index) => (
                        <div key={index} className='--card-news'>

                            <div className='--card-news-image' style={{backgroundImage: `url(${news.image})`}}></div>

                            <div className='--card-news-texto'>
                                <p className='--badge-new'>{news.typo}</p>
                                <h3>
                                    <Link to={''}>{news.title}</Link>
                                </h3>
                                <p className='--txt-description'>{news.texto}</p>
                                <Link to={''} className='--a-more'>Más detalles</Link>
                            </div>

                        </div>
                    ))}

                </div>

            </div>

        </section>

    )

}

export default Newletter