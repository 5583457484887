import React from 'react'
import HeroEdu from '../components/Sections/HeroEdu'

import banner from '../static/img/banner-inicial.png'
import AboutLevel from '../components/Sections/AboutLevel'
import RequirementsLevel from '../components/Sections/RequirementsLevel'

function InicialView() {

    return (

        <>
        
            <HeroEdu banner={banner} title={'Educación Inicial'} content={'Con una metodología educativa lúdica y participativa, con docentes expertos en su área y programas internacionales que brindan una visión global de la enseñanza.'} go={'Solicitar información'}/>

            <AboutLevel level={'inicial'} txtP={'Educación Inicial'} txtS={'para los pequeños del hogar.'} />

            <RequirementsLevel level={'inicial'} />

        </>

    )

}

export default InicialView