import React from 'react'
import './styles/footer.css'
import { IconBrandFacebook, IconBrandInstagram, IconBrandLinkedin, IconBrandTiktok, IconBrandX, IconInfoCircle } from '@tabler/icons-react'
import { Link } from 'react-router-dom'

function Footer() {

    return (

        <footer className={`--footer`}>

            <div className='--content-footer'>

                <div className='--col --col-A'>
                    <div className='--tit'>
                        <h6>Servicios</h6>
                    </div>
                    <ul className='--list --list-services'>
                        <li>
                            <Link to={'https://www.facebook.com/crucedeespadas.15'} target='_blank'><span>CADETES PREPOLI</span></Link>
                        </li>
                        <li>
                            <Link to={'https://www.facebook.com/sigma.iep'} target='_blank'><span>NIVELACIÓN DE ÁREAS</span></Link>
                        </li>
                    </ul>
                </div>
                <div className='--col --col-B'>
                    <div className='--tit'>
                        <h6>Programas</h6>
                    </div>
                    <ul className='--list --list-program'>
                        <li>
                            <Link to={'/educacion/inicial'}><span>EDUCACIÓN INICIAL</span></Link>
                        </li>
                        <li>
                            <Link to={'/educacion/primaria'}><span>EDUCACIÓN PRIMARIA</span></Link>
                        </li>
                        <li>
                            <Link to={'/educacion/secundaria'}><span>EDUCACIÓN SECUNDARIA</span></Link>
                        </li>
                        <li>
                            <Link to={'/educacion/prepoli'}><span>PREPOLI</span></Link>
                        </li>
                        <li>
                            <Link to={'/educacion/cepre'}><span>CREPRE SIGMA</span></Link>
                        </li>
                        <li>
                            <Link to={'/educacion/vacacional'}><span>VACACIONAL</span></Link>
                        </li>
                    </ul>
                </div>
                <div className='--col --col-C'>
                    <div className='--tit'>
                        <h6>Más información</h6>
                    </div>
                    <ul className='--list --list-info'>
                        <li>
                            <Link to={'/blog'}><span>Blog</span></Link>
                        </li>
                        <li>
                            <Link to={'https://portal.sigma-jauja.edu.pe/'} target='_blank'><span>Portal de Estudiantes</span></Link>
                        </li>
                        <li>
                            <Link to={'https://portal.sigma-jauja.edu.pe/'} target='_blank'><span>Portal de Maestros</span></Link>
                        </li>
                        <li>
                            <Link to={'https://maps.app.goo.gl/6wxPtjDnhvDYZsLM6'} target='_blank'><span>Ubícanos: Jr. Junín 975 - 977 - JAUJA</span></Link>
                        </li>
                        <li>
                            <Link to={'tel:+51984002480'}><span>Llámanos: 984 002 480</span></Link>
                        </li>
                        <li>
                            <Link to={'mailto:elcorreoquequieres@correo.com?Subject=Aquí%20el%20asunto%20del%20mail&quot;'} target='_blank'><span>complejoeducativo.sigma@gmail.com</span></Link>
                        </li>
                    </ul>
                </div>
                <div className='--col --col-E'>
                    <div className='--content'>
                        <div className='--book'>
                            <Link to={'/'}>
                                <img src={'https://ucontinental.edu.pe/site/wp-content/uploads/2023/10/LIBRO-DE-RECLAMACIONES-03.jpg.webp'} alt='Libro de reclamaciones de Colegios & Academias SIGMA' />
                            </Link>
                        </div>
                        <div className='--ambiental'>
                            <div className='--image'>
                                <img src={'https://ucontinental.edu.pe/site/wp-content/uploads/2023/06/e-b-certificada.png.webp'} alt='Colegios & Academias SIGMA cumple con los estándares ambientales' />
                            </div>
                            <p>Esta empresa cumple con los más altos estándares de impacto social y ambiental</p>
                        </div>
                        <div className='--info'>
                            <span><IconInfoCircle/></span>
                            <span>RUC Nro. 1085963254</span>
                        </div>
                        <div className='--newtworks'>
                            <ul className='--list-networks'>
                                <li>
                                    <Link to={'/'} target='_blank'><IconBrandFacebook/></Link>
                                </li>
                                <li>
                                    <Link to={'/'} target='_blank'><IconBrandX/></Link>
                                </li>
                                <li>
                                    <Link to={'/'} target='_blank'><IconBrandInstagram/></Link>
                                </li>
                                <li>
                                    <Link to={'/'} target='_blank'><IconBrandLinkedin/></Link>
                                </li>
                                <li>
                                    <Link to={'/'} target='_blank'><IconBrandTiktok/></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

        </footer>

    )

}

export default Footer