import React from "react";
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { UIProvider } from "./context/UIContext";

import HomeScreen from "./screens/HomeScreen";
import ErrorScreen from "./screens/ErrorScreen";

import './static/css/global.css'

const router = createBrowserRouter([
    {
        path: '/',
        element: <HomeScreen/>,
        errorElement: <ErrorScreen/>,
        children: [
            {
                path: '/educacion',
                element: <HomeScreen/>,
                children: [
                    {
                        path: '/educacion/inicial',
                        element: <HomeScreen/>,
                    },
                    {
                        path: '/educacion/primaria',
                        element: <HomeScreen/>,
                    },
                    {
                        path: '/educacion/secundaria',
                        element: <HomeScreen/>,
                    },
                    {
                        path: '/educacion/prepoli',
                        element: <HomeScreen/>,
                    }
                ]
            },
            {
                path: '/nosotros',
                element: <HomeScreen/>
            },
            {
                path: '/experiencia',
                element: <HomeScreen/>,
            },
            {
                path: '/noticias-eventos',
                element: <HomeScreen/>,
            },
            {
                path: '/postulate',
                element: <HomeScreen/>,
            }
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(

    <UIProvider>
        <RouterProvider router={router} />
    </UIProvider>

);