import React from 'react'
import Hero from '../components/Sections/Hero'
import WhyAbout from '../components/Sections/WhyAbout'
import Info from '../components/Sections/Info'
import Experience from '../components/Sections/Experience'
import Reasons from '../components/Sections/Reasons'
import Newletter from '../components/Sections/Newletter'

function HomeView() {

    return (

        <>
        
            <Hero/>

            <WhyAbout/>

            <Info/>

            <Experience/>

            <Reasons/>

            <Newletter/>

        </>

    )

}

export default HomeView