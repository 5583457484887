import React from 'react'
import { Link } from 'react-router-dom'

import imageInfo from '../../static/img/Group-4069.png'

import './styles/info.css'

function Info() {

    return (

        <section className={`--section --section-info`}>

            <div className={`--content-section-info`}>

                <div className={`--title-info`}>
                    <h2 className={`--txt-sized --txt-seconday`}>Te acompañamos en esta importante</h2>
                    <h2 className={`--txt-sized --txt-primary`}>parte de tu vida.</h2>
                </div>

                <div className='--content-info'>

                    <div className='--image-info'>

                        <div className='--perpview'>
                            <img src={imageInfo} alt='Solicita más informacion para ser parte denuestra familia' />
                        </div>

                    </div>

                    <div className='--form-info'>
                        <form className='--form' method='POST'>
                            <div className='--form-group'>
                                <h3>Solicita más información</h3>
                            </div>
                            <div className='--form-group --form-group-flex'>
                                <div className='--form-control'>
                                    <label className='--label' aria-label='Apellido Paterno' htmlFor='lastname'>Apellido Paterno (*)</label>
                                    <input type='text' className='--entry' name='lastname' id='lastname' placeholder='Apellido Paterno' aria-label='Apellido Paterno'/>
                                </div>
                                <div className='--form-control'>
                                    <label className='--label' aria-label='Apellido Materno' htmlFor='secondname'>Apellido Materno (*)</label>
                                    <input type='text' className='--entry' name='secondname' id='secondname' placeholder='Apellido Materno' aria-label='Apellido Materno'/>
                                </div>
                            </div>
                            <div className='--form-group'>
                                <div className='--form-control'>
                                    <label className='--label' aria-label='Nombres Completos' htmlFor='fullname'>Nombres Completos (*)</label>
                                    <input type='text' className='--entry' name='fullname' id='fullname' placeholder='Nombres Completos' aria-label='Nombres Completos'/>
                                </div>
                            </div>
                            <div className='--form-group --form-group-flex'>
                                <div className='--form-control'>
                                    <label className='--label' aria-label='Teléfono' htmlFor='phone'>Teléfono (*)</label>
                                    <input type='text' className='--entry' name='phone' id='phone' placeholder='Teléfono' aria-label='Teléfono'/>
                                </div>
                                <div className='--form-control'>
                                    <label className='--label' aria-label='Email' htmlFor='email'>Email (*)</label>
                                    <input type='email' className='--entry' name='email' id='email' placeholder='Email' aria-label='Email'/>
                                </div>
                            </div>
                            <div className='--form-group'>
                                <div className='--form-control'>
                                    <label className='--label' aria-label='Seleccionar Nivel' htmlFor='level'>Seleccionar Nivel (*)</label>
                                    <select className='--select' name='level' id='level' defaultValue={''}>
                                        <option value={''} selected hidden>Seleccionar Nivel</option>
                                        <option value={'i'}>Incial</option>
                                        <option value={'p'}>Primaria</option>
                                        <option value={'s'}>Secundaria</option>
                                        <option value={'pp'}>Pre Poli</option>
                                    </select>
                                </div>
                            </div>
                            <div className='--form-group'>
                                <div className='--form-control --form-control-flex'>
                                    <input type='checkbox' name='ppr' id='ppr'/>
                                    <label className='--label' htmlFor='ppr'>Acepto las <Link to={'/'}>Políticas de Privacidad</Link></label>
                                </div>
                            </div>
                            <div className='--form-group'>
                                <button className='--btn'>Enviar</button>
                            </div>
                        </form>
                    </div>

                </div>

            </div>

        </section>

    )

}

export default Info