import React from 'react'
import { IconQuote } from '@tabler/icons-react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';

import "swiper/css";

import './styles/reasons.css'

function Reasons() {

    const listReasons = [
        {
            texto: 'Nuestra institución destaca por ofrecer horarios flexibles que se adaptan a tu vida laboral. Además, nuestra plataforma te permite conectarte desde cualquier lugar, facilitando así tu acceso a la educación. Experimenta una calidad educativa excepcional respaldada por nuestro equipo de profesores altamente calificados.',
            student: 'Juan Pérez',
            position_work: 'Ingeniero Civil',
            college: 'Colegios & Academias Sigma'
        },
        {
            texto: 'En Colegios & Academias Sigma, nos enorgullece ofrecer horarios flexibles que se ajustan a tu vida laboral. Además, nuestra plataforma de aprendizaje en línea te brinda la oportunidad de estudiar desde cualquier lugar, permitiéndote equilibrar tu vida profesional y académica. Descubre una educación de calidad respaldada por nuestro dedicado equipo de profesores.',
            student: 'María García',
            position_work: 'Ingeniera de Software',
            college: 'Colegios & Academias Sigma'
        },
        {
            texto: 'Colegios & Academias Sigma es tu mejor opción gracias a nuestra flexibilidad horaria y plataforma de aprendizaje en línea. Estudia desde cualquier lugar y en cualquier momento, adaptando tus estudios a tu vida laboral y personal. Nuestro compromiso con la excelencia educativa se refleja en la calidad de nuestros profesores y programas académicos.',
            student: 'Luis Rodríguez',
            position_work: 'Empresario',
            college: 'Colegios & Academias Sigma'
        }
    ];    

    return (

        <section className={`--section-reasons`}>

            <div className={`--content-section-reasons`}>

                <div className='--titles-reasons'>
                    <p className='--txt-extra'>Razones</p>
                </div>


                <div className='--content-reasons'>
                    <Swiper
                        className='--swip --swip-reasons'
                        loop={true}
                        spaceBetween={30}
                        autoplay={{ delay: 9000 }}
                        modules={[Autoplay]}
                    >

                        {listReasons.map((reason, index) => (

                            <SwiperSlide key={index}>
                                
                                <div className='--swip-item-reaseons'>

                                    <div className='--swip-item-reaseons-h'>
                                        <h3>Ellos y ellas estudian con nosotros</h3>
                                        <p>Nuestras egresadas y egresados cuentan sus experiencias, sus actuales logros en el campo laboral y las razones por las que estudiaron en la Universidad Continental.</p>
                                    </div>

                                    <div className='--swip-item-reaseons-b'>

                                        <div className='--col-swip-item --col-swip-item-A'>

                                            <div className='--view-video'>

                                                <div className='--figure --figure-rectangule'></div>
                                                <div className='--video'></div>
                                                <div className='--figure --figure-square'></div>

                                            </div>

                                        </div>

                                        <div className='--col-swip-item --col-swip-item-B'>

                                            <p><span className='--ico-comillas --ico-comillas-firts'><IconQuote/></span>{reason.texto}<span className='--ico-comillas'><IconQuote/></span></p>
                                            <h5>{reason.student}</h5>
                                            <h6>{reason.position_work}</h6>
                                            <h6>{reason.college}</h6>

                                        </div>

                                    </div>

                                </div>

                            </SwiperSlide>
                        
                        ))}

                    </Swiper>
                </div>

            </div>

        </section>

    )

}

export default Reasons