import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { IconBrandFacebook, IconMail, IconMenu, IconX } from '@tabler/icons-react'

import UIContext from '../context/UIContext'

import logo from '../static/img/logo.svg'

import './styles/header.css'

function Header() {

    const { toogleMenu, handleToogleMenu } = useContext(UIContext);

    return (

        <header className={`--header`}>

            <div className='--header-A'>

                <div className={`--content-header-A`}>

                    <ul className='--ul-importants'>
                        <li className={`--li-importants`}>
                            <Link to={'mailto:elcorreoquequieres@correo.com?Subject=Aquí%20el%20asunto%20del%20mail"'} target='_blank' className={`--a-importants --a-importants-ico`}>
                                <IconMail/>
                            </Link>
                        </li>
                        <li className={`--li-importants`}>
                            <Link to={'https://www.facebook.com/sigma.iep'} target='_blank' className={`--a-importants --a-importants-ico`}>
                                <IconBrandFacebook/>
                            </Link>
                        </li>
                        <li className={`--li-importants`}>
                            <Link to={'https://portal.sigma-jauja.edu.pe/'} target='_blank' className={`--a-importants`}>
                                <span className={`--txt-importants`}>Portal Estudiantes</span>
                            </Link>
                        </li>
                        <li className={`--li-importants`}>
                            <Link to={'https://portal.sigma-jauja.edu.pe/'} target='_blank' className={`--a-importants`}>
                                <span className={`--txt-importants`}>Portal Profesores</span>
                            </Link>
                        </li>
                    </ul>

                </div>

            </div>

            <div className='--header-B'>

                <div className={`--content-header-B`}>

                    <div className={`--col --col-A`}>
                        <Link to={'/'}>
                            <img src={logo} alt='Logo de la Institución Educativa Particular Sigma | PREPOLI | Colegios & Academias | Jauja' />
                        </Link>
                    </div>

                    <div className={`--col --col-B`}>

                        <ul className={`--nav`}>
                            <li className={`--li-nav --li-nav-sub`}>
                                <Link to={'#'} className={`--a-nav`}>
                                    <span>Nuestros Programas</span>
                                </Link>
                                <ul className='--submenu'>
                                    <li className='--li-submenu'>
                                        <Link to={'/educacion/inicial'}><span>Educación Inicial</span></Link>
                                    </li>
                                    <li className='--li-submenu'>
                                        <Link to={'/educacion/primaria'}><span>Educación Primaria</span></Link>
                                    </li>
                                    <li className='--li-submenu'>
                                        <Link to={'/educacion/secundaria'}><span>Educación Secundaria</span></Link>
                                    </li>
                                    <li className='--li-submenu'>
                                        <Link to={'/educacion/prepoli'}><span>Educación PRE-POLI</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={`--li-nav`}>
                                <Link to={'/nosotros'} className={`--a-nav`}>
                                    <span>¿Por qué Sigma?</span>
                                </Link>
                            </li>
                            <li className={`--li-nav`}>
                                <Link to={'/experiencia'} className={`--a-nav`}>
                                    <span>Experiencia</span>
                                </Link>
                            </li>
                            <li className={`--li-nav`}>
                                <Link to={'/noticias-eventos'} className={`--a-nav`}>
                                    <span>Noticias</span>
                                </Link>
                            </li>
                            <li className={`--li-nav`}>
                                <Link to={'/postulate'} className={`--a-nav --a-nav-primary`}>
                                    <span>Postula Aquí</span>
                                </Link>
                            </li>
                        </ul>

                    </div>

                    <div className={`--col --col-C`}>
                        <button className={`--btn --btn-menu`} onClick={handleToogleMenu}>{toogleMenu ? <IconX/> : <IconMenu/>}</button>
                    </div>

                </div>

            </div>

        </header>

    )

}

export default Header