import React from 'react'
import { Link } from 'react-router-dom'

import './styles/heroedu.css'

function HeroEdu({ banner, title, content, go }) {

    return (

        <section className={`--section-hero-edu`}>

            <div className={`--content-hero-edu`}>

                <div className={`--banner-hero-edu`} style={{backgroundImage: `url(${banner})`}}>

                    <div className={`--content-banner-txt`}>
                        <h1 aria-label={title}>{title}</h1>
                        <p aria-label={content}>{content}</p>
                        <Link to={'#'}>{go}</Link>
                    </div>

                </div>

            </div>

        </section>

    )

}

export default HeroEdu