import React from 'react'
import HeroEdu from '../components/Sections/HeroEdu'

import banner from '../static/img/banner-prepoli.png'
import AboutLevel from '../components/Sections/AboutLevel'
import RequirementsLevel from '../components/Sections/RequirementsLevel'

function PrePoliView() {

    return (

        <>
        
            <HeroEdu banner={banner} title={'PRE POLI'} content={'Con una metodología educativa intensiva y disciplinada, con instructores experimentados y programas especializados que preparan a los estudiantes para los desafíos físicos, mentales y éticos de la preparación pre policial y militar.'} go={'Solicitar información'} />

            <AboutLevel level={'prepoli'} txtP={'Educación PRE POLI'} txtS={'para nuestros próximos líderes.'} />

            <RequirementsLevel level={'prepoli'} />

        </>

    )

}

export default PrePoliView