import React from 'react'
import './styles/menu.css'
import { Link } from 'react-router-dom'

function Menu() {

    return (

        <div className={`--overlay`}>

            <ul className='--menu'>

                <li className={`--li-menu --li-submenu`}>
                    <Link to={'#'} className={`--a-menu`}>
                        <span>Nuestros Programas</span>
                    </Link>
                    <ul className={`--submenu`}>
                        <li>
                            <Link to={'/educacion/inicial'}><span>Educación Inicial</span></Link>
                        </li>
                        <li>
                            <Link to={'/educacion/primaria'}><span>Educación Primaria</span></Link>
                        </li>
                        <li>
                            <Link to={'/educacion/secundaria'}><span>Educación Secundaria</span></Link>
                        </li>
                        <li>
                            <Link to={'/educacion/prepoli'}><span>Educación PRE POLICIAL</span></Link>
                        </li>
                    </ul>
                </li>
                <li className={`--li-menu`}>
                    <Link to={'/nosotros'} className={`--a-menu`}>
                        <span>¿Por qué Sigma?</span>
                    </Link>
                </li>
                <li className={`--li-menu`}>
                    <Link to={'/experiencia'} className={`--a-menu`}>
                        <span>Experiencia</span>
                    </Link>
                </li>
                <li className={`--li-menu`}>
                    <Link to={'/noticias-eventos'} className={`--a-menu`}>
                        <span>Noticias</span>
                    </Link>
                </li>
                <li className={`--li-menu`}>
                    <Link to={'/postulate'} className={`--a-menu --a-menu-primary`}>
                        <span>Postula Aquí</span>
                    </Link>
                </li>

            </ul>

        </div>

    )

}

export default Menu