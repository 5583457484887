import React, { createContext, useState } from 'react'

const UIContext = createContext();

export const UIProvider = ({ children }) => {

    const [ toogleMenu, setToogleMenu ] = useState(false);

    const handleToogleMenu = () => setToogleMenu(!toogleMenu);

    const contextValue = {
        toogleMenu, handleToogleMenu
    }

    return (
        <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
    )

}

export default UIContext;