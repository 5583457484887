import React from 'react'
import { useLocation } from 'react-router-dom'

import HomeView from '../views/HomeView';
import InicialView from '../views/InicialView';
import PrimariaView from '../views/PrimariaView';
import SecundariaView from '../views/SecundariaView';

import './styles/main.css'
import PrePoliView from '../views/PrePoliView';

function Main() {

    const pathname = useLocation();

    return (

        <main className={`--main`}>

            {pathname.pathname === '/' && ( <HomeView/> )}

            {pathname.pathname === '/educacion/inicial' && ( <InicialView/> )}
            {pathname.pathname === '/educacion/primaria' && ( <PrimariaView/> )}
            {pathname.pathname === '/educacion/secundaria' && ( <SecundariaView/> )}
            {pathname.pathname === '/educacion/prepoli' && ( <PrePoliView/> )}


            {pathname.pathname === '/nosotros' && ( <h1>Nosotros</h1> )}
            {pathname.pathname === '/experiencia' && ( <h1>Experiencia</h1> )}
            {pathname.pathname === '/noticias-eventos' && ( <h1>Noticias y Eventos</h1> )}
            {pathname.pathname === '/postulate' && ( <h1>Ingreso Seguro</h1> )}

        </main>

    )

}

export default Main