import React, { useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { IconBrandWhatsapp } from '@tabler/icons-react';

import UIContext from '../context/UIContext'

import Menu from '../components/Modal/Menu';

import Header from '../layout/Header'

import './styles/error.css'

function ErrorScreen() {

    const { toogleMenu } = useContext(UIContext);

    const location = useLocation();

    useEffect(() => {

        const titleMappings = {
            '/': 'Colegios & Academias Sigma | Educación de Calidad',
            '/educacion': 'Educación | Colegios & Academias Sigma | Educación de Calidad',
            '/educacion/inicial': 'INICIAL | Colegios & Academias Sigma | Educación de Calidad',
            '/educacion/primaria': 'PRIMARIA | Colegios & Academias Sigma | Educación de Calidad',
            '/educacion/secundaria': 'SECUNDARIA | Colegios & Academias Sigma | Educación de Calidad',
            '/educacion/prepoli': 'PREPOLI | Colegios & Academias Sigma | Educación de Calidad',
            '/nosotros': 'Nosotros | Colegios & Academias Sigma | Educación de Calidad',
            '/experiencia': 'Experiencia | Colegios & Academias Sigma | Educación de Calidad',
            '/noticias-eventos': 'Noticias y Eventos | Colegios & Academias Sigma | Educación de Calidad',
            '/postulate': 'Postulación | Colegios & Academias Sigma | Educación de Calidad',
            'default': 'Colegios & Academias Sigma | Educación de Calidad'
        };
        
        const path = location.pathname;
        const title = titleMappings[path] || titleMappings['default'];
        document.title = title;

    })

    return (

        <div className='--app'>

            <Link to={'https://api.whatsapp.com/send/?phone=51984002480&text=Hola%2C+necesito+mas+informaci%C3%B3n&type=phone_number&app_absent=0'} target='_blank' className='--a-whatsApp-elevated'>
                <div className='--content-a-whatsApp-elevated'>
                    <span className='--txt-a-whatsApp-elevated'>Solicitar más información</span>
                    <IconBrandWhatsapp/>
                </div>
            </Link>

            <Header/>


            <div className='--error-section'>

                <div className='--txt'>
                    <h2>404</h2>
                    <p className='--p --p-bold'>Página no encontrada.</p>
                    <p className='--p --p-small'>Aún estamos trabajando para una mejor experiencia.</p>
                    <Link to={'/'}>Volver al inicio</Link>
                </div>

            </div>

            {toogleMenu && (<Menu/>) }

        </div>

    )
    
}

export default ErrorScreen